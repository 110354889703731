
.homeContainer
  .logo
    background: url('~assets/teamone-bg.jpg')
    width: 100%
    min-height: 800px
    height: auto
    background-repeat: no-repeat
    background-size: contain
    @media screen and (max-width: 1240px)
      min-height: 400px !important
    @media screen and (max-width: 600px)
      min-height: 200px !important
      background-size: cover
  .validator-icon
    width: 200px
    margin-right: 150px
    @media screen and (max-width: 600px)
      width: 100px !important
